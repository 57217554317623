<template>
  <div class="wrapper">
    <!-- 活动名称 -->
    <div class="title-box">
      <el-image
        style="width: 80px; height: 80px; border-radius: 5px"
        :src="detail.coverImage"
        :preview-src-list="[detail.coverImage]"
        fit="cover"
      ></el-image>
      <div class="title-box-right">
        <div class="name">{{ detail.name }}</div>
        <div v-if="detail.type === 0" class="address">活动地址：{{ detail.address }}</div>
        <div v-if="detail.type === 1" class="address">
          <el-tag size="mini" type="primary" v-if="detail.gamingTypeInfo">
            <span>
              {{ detail.gamingTypeInfo.name }}
            </span>
            <span v-if="detail.gameDistrict">-</span>
            <span>
              {{ detail.gameDistrict }}
            </span>
          </el-tag>
        </div>
      </div>
    </div>
    <!-- 状态 -->
    <div class="status-box">
      <el-descriptions direction="vertical" :column="5" border :labelStyle="{ color: '#333' }">
        <el-descriptions-item label="状态">
          <el-tag v-if="detail.status === 0" type="warning" size="small">{{ detail.statusName }}</el-tag>
          <el-tag v-if="detail.status === 1" type="success" size="small">{{ detail.statusName }}</el-tag>
          <el-tag v-if="detail.status === 2" type="danger" size="small">{{ detail.statusName }}</el-tag>
          <el-tag v-if="detail.status === 3" type="info" size="mini">{{ detail.statusName }}</el-tag>
          <el-tag v-if="detail.status === 4" type="primary" size="small">{{ detail.statusName }}</el-tag>
          <el-tag v-if="detail.status === 5" type="success" size="mini">{{ detail.statusName }}</el-tag>
          <el-tag v-if="detail.status === 6" type="warning" size="mini">{{ detail.statusName }}</el-tag>
          <el-tag v-if="detail.status === 7" type="danger" size="mini">{{ detail.statusName }}</el-tag>
          <el-tag v-if="detail.status === 8" type="info" size="mini">{{ detail.statusName }}</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="性别限制">
          <span v-if="detail.sexRequireList[0]">{{ detail.sexRequireList[0].name }}</span>
        </el-descriptions-item>
        <el-descriptions-item label="买单类型" v-if="detail.type === 0">
          <el-tag v-if="detail.paymentType == 1" type="primary" size="small">{{ detail.paymentTypeName }}</el-tag>
          <el-tag v-if="detail.paymentType == 2" type="success" size="small">{{ detail.paymentTypeName }}</el-tag>
          <el-tag v-if="detail.paymentType == 3" type="warning" size="small">{{ detail.paymentTypeName }}</el-tag>
          <el-tag v-if="detail.paymentType == 0" type="info" size="small">{{ detail.paymentTypeName }}</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="是否设置参加奖励" :span="1">
          <el-tag v-if="detail.rewardStatus == 1" type="primary" size="small">是</el-tag>
          <el-tag v-if="detail.rewardStatus == 0" type="danger" size="small">否</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="是否身份保密">
          <el-tag v-if="detail.identityConfidential == 1" type="primary" size="small">是</el-tag>
          <!-- <el-tag v-if="detail.identityConfidential == 2" type="danger" size="small">否</el-tag> -->
          <el-tag v-if="detail.identityConfidential == 0" type="warning" size="small">否</el-tag>
        </el-descriptions-item>
      </el-descriptions>
    </div>

    <!-- tabs  -->
    <div class="tabs-box">
      <el-tabs v-model="activeName" @tab-click="handleClickTab">
        <el-tab-pane label="活动信息" name="intro">
          <admin-title title="活动时间" size="14px" bottom="10px"></admin-title>
          <el-descriptions style="margin-bottom: 10px" :column="3">
            <el-descriptions-item label="开始时间">{{ detail.startTime }}</el-descriptions-item>
            <el-descriptions-item label="结束时间">{{ detail.endTime }}</el-descriptions-item>
            <el-descriptions-item label="创建时间">{{ detail.createTime }}</el-descriptions-item>
          </el-descriptions>
          <admin-title title="活动内容" size="14px" bottom="10px"></admin-title>
          <el-descriptions style="margin-bottom: 10px" :column="3">
            <el-descriptions-item label="活动人数" :span="1">{{ detail.peopleNumber }}</el-descriptions-item>
            <el-descriptions-item label="当前人数" :span="1">{{ detail.currentNumber }}</el-descriptions-item>
            <el-descriptions-item label="浏览量" :span="1">{{ detail.pv }}</el-descriptions-item>
            <el-descriptions-item label="每人金额" :span="1"> {{ detail.everyRewardMoney || '无参与奖励' }}</el-descriptions-item>
            <el-descriptions-item v-if="detail.type === 0" label="城市" :span="1">{{ detail.cityName }}</el-descriptions-item>
            <el-descriptions-item v-if="detail.type === 0" label="地址" :span="1">{{ detail.address }}</el-descriptions-item>
            <el-descriptions-item label="活动简介" :span="3">{{ detail.content }}</el-descriptions-item>
          </el-descriptions>
          <admin-title title="发布者信息" size="14px" bottom="10px"></admin-title>
          <el-descriptions style="margin-bottom: 10px" :column="3">
            <el-descriptions-item label="昵称">{{ detail.releaseUserInfo?.nickname }}</el-descriptions-item>

            <el-descriptions-item label="手机号">{{ detail.releaseUserInfo?.mobile }}</el-descriptions-item>
            <el-descriptions-item label="聊天账号id">{{ detail.releaseUserInfo?.imUserId }}</el-descriptions-item>
            <el-descriptions-item label="头像">
              <el-image
                style="width: 60px; height: 60px; border-radius: 5px"
                :src="detail.releaseUserInfo.face"
                fit="contain"
                :preview-src-list="[detail.releaseUserInfo.face]"
              ></el-image>
            </el-descriptions-item>
          </el-descriptions>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Detail',
  props: {
    detail: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      activeName: 'intro'
    }
  },

  mounted() {},

  methods: {
    handleClickTab() {}
  }
}
</script>
<style lang="scss" scoped>
.wrapper {
  padding: 20px 0;
  .title-box {
    display: flex;
    margin-bottom: 20px;
    padding: 0 20px;
    .title-box-right {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      margin-left: 20px;
      .name {
        font-weight: 600;
      }
    }
  }
  .status-box {
    padding: 0 20px;
  }
  .tabs-box {
    margin: 20px 0;
    padding: 0 20px;
  }
}
</style>
