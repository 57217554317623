<template>
  <!--  -->
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <el-form ref="form" inline :model="searchData" label-width="">
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item label="活动类型">
            <!-- <el-radio-group v-model="searchData.type" size="mini">
              <el-radio-button :label="1">线上游戏组局</el-radio-button>
              <el-radio-button :label="0">线下活动组局</el-radio-button>
            </el-radio-group> -->
            <a-radio-group v-model="searchData.type" :default-value="1" button-style="solid">
              <a-radio-button :value="1"> 线上游戏组局 </a-radio-button>
              <a-radio-button :value="0"> 线下活动组局 </a-radio-button>
            </a-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="活动名称">
        <el-input style="width: 200px" clearable v-model="searchData.name" size="mini" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="组局类型">
        <SelectGroupType v-model="searchData.gamingTypeId" width="200px"></SelectGroupType>
      </el-form-item>
      <el-form-item label="城市">
        <el-input style="width: 200px" clearable v-model="searchData.name" size="mini" placeholder="请输入"></el-input>
      </el-form-item>
      <!-- <el-form-item label="时间范围">
        <el-input style="width: 200px" clearable v-model="searchData.name" size="mini" placeholder="请输入"></el-input>
      </el-form-item> -->
      <el-form-item label=" ">
        <el-button type="primary" size="mini" icon="el-icon-search" @click="getList">搜索</el-button>
        <el-button type="info" size="mini" icon="el-icon-refresh" @click="reset">重置</el-button>
      </el-form-item>
    </el-form>
    <div class="tabs">
      <div :class="['item', searchData.status === -1 ? 'active' : '']" @click="handleClickTab(-1)">
        <el-badge :value="stat.total">
          <span class="tab-name">全部</span>
        </el-badge>
      </div>
      <div :class="['item', searchData.status === 0 ? 'active' : '']" @click="handleClickTab(0)">
        <el-badge :value="stat.waitCount">
          <span class="tab-name">待审核</span>
        </el-badge>
      </div>
      <div :class="['item', searchData.status === 1 ? 'active' : '']" @click="handleClickTab(1)">
        <el-badge :value="stat.passCount">
          <span class="tab-name">已通过</span>
        </el-badge>
      </div>
      <div :class="['item', searchData.status === 2 ? 'active' : '']" @click="handleClickTab(2)">
        <el-badge :value="stat.rejectCount">
          <span class="tab-name">已拒绝</span>
        </el-badge>
      </div>
    </div>
    <!-- list -->
    <!-- 线上 -->
    <el-table v-show="searchData.type === 1" :data="list">
      <!-- 拓展 -->
      <el-table-column type="expand">
        <template slot-scope="{ row }">
          <el-form class="expand">
            <el-row :gutter="10">
              <el-col :span="8">
                <el-form-item label="开始时间">
                  {{ row.startTime }}
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="结束时间">
                  {{ row.endTime }}
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="内容">
                  {{ row.content || '-' }}
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col :span="16">
                <el-form-item label="发布者信息：">
                  <div class="user-box">
                    <el-image
                      class="face"
                      style="width: 80px; height: 80px"
                      :src="row.releaseUserInfo?.face"
                      :preview-src-list="[row.releaseUserInfo?.face]"
                      fit="contain"
                    ></el-image>
                    <div class="user-info">
                      <div class="item">昵称：{{ row.releaseUserInfo?.nickname || '-' }}</div>
                      <div class="item">手机号：{{ row.releaseUserInfo?.mobile || '-' }}</div>
                      <div class="item">聊天账号id：{{ row.releaseUserInfo?.imUserId || '-' }}</div>
                    </div>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="活动名称" min-width="120" show-overflow-tooltip> </el-table-column>
      <el-table-column prop="peopleNumber" label="活动类型" min-width="120">
        <template slot-scope="{ row }">
          <el-tag v-if="row.type === 1" type="primary" size="mini">线上游戏组局</el-tag>
          <el-tag v-if="row.type === 0" type="success" size="mini">线下活动组局</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="游戏名称" min-width="150">
        <template slot-scope="{ row }">
          <el-tag size="mini" type="primary" v-if="row.gamingTypeInfo">
            <span>
              {{ row.gamingTypeInfo.name }}
            </span>
            <span v-if="row.gameDistrict">-</span>
            <span>
              {{ row.gameDistrict }}
            </span>
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="peopleNumber" label="游戏性质" min-width="100" show-overflow-tooltip>
        <template slot-scope="{ row }">
          <el-tag v-if="row.gameNature === 1" type="primary" size="mini">娱乐消遣</el-tag>
          <el-tag v-if="row.gameNature === 2" type="success" size="mini">技术上分</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="" label="性别限制" min-width="100" show-overflow-tooltip>
        <template slot-scope="{ row }">
          <span v-if="row.sexRequireList[0]">{{ row.sexRequireList[0].name }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="peopleNumber" label="人数" min-width="100" show-overflow-tooltip> </el-table-column>

      <el-table-column prop="everyRewardMoney" label="每人奖励" min-width="100" show-overflow-tooltip>
        <template slot-scope="{ row }">
          {{ row.everyRewardMoney || '无参与奖励' }}
        </template>
      </el-table-column>

      <el-table-column prop="status" label="状态" min-width="100" show-overflow-tooltip>
        <template slot-scope="{ row }">
          <el-tag v-if="row.status === 0" type="warning" size="mini">{{ row.statusName }}</el-tag>
          <el-tag v-if="row.status === 1" type="success" size="mini">{{ row.statusName }}</el-tag>
          <el-tag v-if="row.status === 2" type="danger" size="mini">{{ row.statusName }}</el-tag>
          <el-tag v-if="row.status === 3" type="info" size="mini">{{ row.statusName }}</el-tag>
          <el-tag v-if="row.status === 4" type="primary" size="mini">{{ row.statusName }}</el-tag>
          <el-tag v-if="row.status === 5" type="success" size="mini">{{ row.statusName }}</el-tag>
          <el-tag v-if="row.status === 6" type="warning" size="mini">{{ row.statusName }}</el-tag>
          <el-tag v-if="row.status === 7" type="danger" size="mini">{{ row.statusName }}</el-tag>
          <el-tag v-if="row.status === 8" type="info" size="mini">{{ row.statusName }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="identityConfidential" label="是否匿名" min-width="100" show-overflow-tooltip>
        <template slot-scope="{ row }">
          <el-tag v-if="row.identityConfidential === 0" type="warning" size="mini">否</el-tag>
          <el-tag v-if="row.identityConfidential === 1" type="primary" size="mini">是</el-tag>
          <!-- <el-tag v-if="row.identityConfidential === 2" type="danger" size="mini">否</el-tag> -->
        </template>
      </el-table-column>
      <el-table-column prop="coverImage" label="图片" min-width="120" show-overflow-tooltip>
        <template slot-scope="{ row }">
          <el-image style="width: 60px; height: 60px; border-radius: 5px" :src="row.coverImage" :preview-src-list="[row.coverImage]" fit="cover"> </el-image>
        </template>
      </el-table-column>
      <el-table-column prop="pv" label="浏览量" min-width="100"> </el-table-column>
      <el-table-column prop="createTime" label="创建时间" min-width="160"> </el-table-column>

      <!-- 操作 -->
      <el-table-column prop="id" label="操作" min-width="140" fixed="right">
        <template slot-scope="{ row }">
          <el-link type="primary" :underline="false" @click="getReview(row)">审核</el-link>
          <el-link style="margin: 0 10px" type="primary" :underline="false" @click="getDetail(row)">详情</el-link>
          <!-- <el-link type="primary" :underline="false">删除</el-link> -->
        </template>
      </el-table-column>
    </el-table>
    <!-- 线下 -->
    <el-table v-show="searchData.type === 0" :data="list">
      <!-- 拓展 -->
      <el-table-column type="expand">
        <template slot-scope="{ row }">
          <el-form class="expand">
            <el-row :gutter="10">
              <el-col :span="8">
                <el-form-item label="开始时间">
                  {{ row.startTime }}
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="结束时间">
                  {{ row.endTime }}
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="内容">
                  {{ row.content || '-' }}
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col :span="8">
                <el-form-item label="参与者要求：">
                  <div class="require-info">
                    <div class="item">身高：{{ row.heightRequireList[0]?.name }}</div>
                    <div class="item">体重：{{ row.weightRequireList[0]?.name }}</div>
                    <div class="item">年龄：{{ row.ageRequireList[0]?.name }}</div>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="16">
                <el-form-item label="发布者信息：">
                  <div class="user-box">
                    <el-image
                      class="face"
                      style="width: 80px; height: 80px"
                      :src="row.releaseUserInfo?.face"
                      :preview-src-list="[row.releaseUserInfo?.face]"
                      fit="contain"
                    ></el-image>
                    <div class="user-info">
                      <div class="item">昵称：{{ row.releaseUserInfo?.nickname || '-' }}</div>
                      <div class="item">手机号：{{ row.releaseUserInfo?.mobile || '-' }}</div>
                      <div class="item">聊天账号id：{{ row.releaseUserInfo?.imUserId || '-' }}</div>
                    </div>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="活动名称" min-width="120" show-overflow-tooltip> </el-table-column>
      <el-table-column prop="peopleNumber" label="活动类型" min-width="120">
        <template slot-scope="{ row }">
          <el-tag v-if="row.type === 1" type="primary" size="mini">线上游戏组局</el-tag>
          <el-tag v-if="row.type === 0" type="success" size="mini">线下活动组局</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="" label="性别限制" min-width="100" show-overflow-tooltip>
        <template slot-scope="{ row }">
          <span v-if="row.sexRequireList[0]">{{ row.sexRequireList[0].name }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="peopleNumber" label="人数" min-width="100" show-overflow-tooltip> </el-table-column>
      <el-table-column prop="address" label="活动地点" min-width="160">
        <template slot-scope="{ row }">
          <div class="city-box">
            <div style="margin-bottom: 5px" class="row">城市：{{ row.cityName }}</div>
            <div class="row">地址：{{ row.address }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="address" label="组局买单" min-width="120">
        <template slot-scope="{ row }">
          <el-tag v-if="row.paymentType == 1" type="primary" size="small">{{ row.paymentTypeName }}</el-tag>
          <el-tag v-if="row.paymentType == 2" type="success" size="small">{{ row.paymentTypeName }}</el-tag>
          <el-tag v-if="row.paymentType == 3" type="warning" size="small">{{ row.paymentTypeName }}</el-tag>
          <el-tag v-if="row.paymentType == 0" type="info" size="small">{{ row.paymentTypeName }}</el-tag>
        </template>
      </el-table-column>

      <el-table-column prop="everyRewardMoney" label="每人奖励" min-width="100" show-overflow-tooltip>
        <template slot-scope="{ row }">
          {{ row.everyRewardMoney || '无参与奖励' }}
        </template>
      </el-table-column>

      <el-table-column prop="status" label="状态" min-width="100" show-overflow-tooltip>
        <template slot-scope="{ row }">
          <el-tag v-if="row.status === 0" type="warning" size="mini">{{ row.statusName }}</el-tag>
          <el-tag v-if="row.status === 1" type="success" size="mini">{{ row.statusName }}</el-tag>
          <el-tag v-if="row.status === 2" type="danger" size="mini">{{ row.statusName }}</el-tag>
          <el-tag v-if="row.status === 3" type="info" size="mini">{{ row.statusName }}</el-tag>
          <el-tag v-if="row.status === 4" type="primary" size="mini">{{ row.statusName }}</el-tag>
          <el-tag v-if="row.status === 5" type="success" size="mini">{{ row.statusName }}</el-tag>
          <el-tag v-if="row.status === 6" type="warning" size="mini">{{ row.statusName }}</el-tag>
          <el-tag v-if="row.status === 7" type="danger" size="mini">{{ row.statusName }}</el-tag>
          <el-tag v-if="row.status === 8" type="info" size="mini">{{ row.statusName }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="identityConfidential" label="是否匿名" min-width="100" show-overflow-tooltip>
        <template slot-scope="{ row }">
          <el-tag v-if="row.identityConfidential === 0" type="warning" size="mini">暂无</el-tag>
          <el-tag v-if="row.identityConfidential === 1" type="primary" size="mini">是</el-tag>
          <el-tag v-if="row.identityConfidential === 2" type="danger" size="mini">否</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="coverImage" label="图片" min-width="120" show-overflow-tooltip>
        <template slot-scope="{ row }">
          <el-image style="width: 60px; height: 60px; border-radius: 5px" :src="row.coverImage" :preview-src-list="[row.coverImage]" fit="cover"> </el-image>
        </template>
      </el-table-column>
      <el-table-column prop="pv" label="浏览量" min-width="100"> </el-table-column>
      <el-table-column prop="createTime" label="创建时间" min-width="160"> </el-table-column>

      <!-- 操作 -->
      <el-table-column prop="id" label="操作" min-width="140" fixed="right">
        <template slot-scope="{ row }">
          <el-link type="primary" :underline="false" @click="getReview(row)">审核</el-link>
          <el-link style="margin: 0 10px" type="primary" :underline="false" @click="getDetail(row)">详情</el-link>
          <!-- <el-link type="primary" :underline="false">删除</el-link> -->
        </template>
      </el-table-column>
    </el-table>
    <pagination :total="total" :page.sync="searchData.page" :limit.sync="searchData.pagesize" @pagination="getList" />
    <!-- 详情 -->
    <el-drawer title="活动详情" :with-header="false" :visible.sync="show_detail" direction="rtl" size="50%">
      <Detail :detail="detail"></Detail>
    </el-drawer>
    <!-- 审核弹窗 -->
    <el-dialog title="审核" :visible.sync="show_review" width="700px">
      <div class="review-box">
        <el-form ref="form" :model="formData" label-width="80px">
          <el-form-item label="状态">
            <el-radio-group v-model="formData.status">
              <el-radio :label="1">通过</el-radio>
              <el-radio :label="2">拒绝</el-radio>
            </el-radio-group>
          </el-form-item>

          <transition name="el-zoom-in-top">
            <el-form-item v-if="formData.status == 2" label="拒绝理由">
              <el-input style="width: 400px" type="textarea" v-model="formData.refuseReason" placeholder="请输入"></el-input>
            </el-form-item>
          </transition>
        </el-form>
      </div>

      <div slot="footer">
        <el-button @click="show_review = false" size="small">取 消</el-button>
        <el-button type="primary" @click="submitReview" size="small">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getListAPI, changeStatusAPI, getStatAPI } from './api'
import { authBtnMixin } from '@/mixins/authBtnMixin'
import Detail from './detail.vue'
import SelectGroupType from '@/views/activity/group-type/select-group-type.vue'
export default {
  name: 'Index',
  components: { Detail, SelectGroupType },
  mixins: [authBtnMixin],
  data() {
    return {
      show_review: false, //审核弹窗
      show_detail: false, //详情弹窗
      list: [],
      searchData: {
        page: 1,
        pagesize: 10,
        name: '',
        cityId: '',
        gamingTypeId: '',
        gamingOptionId: '',
        status: -1,
        type: 1
      },
      total: 0,
      detail: {},
      formData: {
        id: '',
        status: 1,
        refuseReason: ''
      },
      stat: {}
    }
  },

  watch: {
    'searchData.type': {
      handler() {
        this.getList()
      }
    }
  },
  activated() {
    this.getList()
  },
  mounted() {},

  methods: {
    // 列表
    async getList() {
      let params = { ...this.searchData }
      if (this.searchData.status == -1) {
        params.status = ''
      }
      const res = await getListAPI(params)
      this.total = res.totalRow
      this.list = res.records
      this.getStat()
    },
    async getStat() {
      this.stat = await getStatAPI()
    },
    // 详情
    getDetail(row) {
      this.detail = row
      this.show_detail = true
    },
    // 审核弹窗
    getReview(row) {
      this.formData = {
        id: row.id,
        status: 1,
        refuseReason: ''
      }

      this.show_review = true
    },
    // 提交审核
    submitReview() {
      changeStatusAPI(this.formData).then(() => {
        this.$message.success('操作成功')
        this.getList()
        this.show_review = false
        this.$bus.$emit('updateTip')
      })
    },
    // 点击tab
    handleClickTab(i) {
      if (this.searchData.status == i) return
      this.searchData.status = i
      this.getList()
    },
    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  .expand /deep/ {
    .el-form-item__label {
      color: #909399;
    }
    .user-box {
      display: flex;
      // align-items: center;
      .face {
        margin-right: 10px;
        border-radius: 5px;
      }
      .user-info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        line-height: 20px;
        height: 80px;
      }
    }
  }
  .review-box /deep/ {
    background: #f5f5f5;
    padding: 30px;
    border-radius: 6px;
    transition: all 0.2s ease;
  }
  .tabs {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 1px solid #dcdfe6;
    .item {
      cursor: pointer;
      position: relative;
      padding-bottom: 5px;
      margin-right: 20px;
      // border-bottom: 2px solid #409eff;

      .tab-name {
        padding: 6px;
      }
    }
    .active {
      color: #409eff;
      font-weight: 600;
      &::after {
        content: '';
        display: inline-block;
        height: 2px;
        width: 25px;
        background: #409eff;
        position: absolute;
        bottom: -2px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 5px;
      }
    }
  }
  .require-info {
    display: flex;
    flex-direction: column;
    line-height: 30px;
  }
}
</style>
